import React from 'react';
import Form from '../components/Form';
import Layout from '../components/Layout';
import NewsletterFormSidebar from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';

export default function ContactPage() {
  return (
    <Layout>
      <SEO />
      <div className="border-b border-gray-300 bg-gray-100">
        <div className="container py-12  md:pb-24 md:pt-24 ">
          <h1 className="text-gray-800 font-bold text-3xl md:text-5xl mt-0">Contact</h1>
        </div>
      </div>
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap">
          <div className="md:w-3/4 w-full md:border-r border-gray-300 md:pr-6">
            <p>
              Contact me through this form below,{' '}
              <a
                className="text-green-500 font-semibold"
                href="http://www.twitter.com/juvoni"
                target="_blank"
                rel="noopener noreferrer"
              >
                @juvoni
              </a>{' '}
              or{' '}
              <a className="text-green-500 font-semibold" href="mailto:message@juvoni.com">
                message@juvoni.com
              </a>
              . I would love to hear your thoughts on any writings, ideas, suggestions or other kind
              words.
            </p>
            <p>
              If you have any book suggestions, send them over. Tell me a little about the book and
              how it has impacted you.
            </p>
            <Form />
          </div>
          <div className="md:w-1/4 w-full md:pl-6 ">
            <NewsletterFormSidebar />
            {/* <ShareArticle post={content}></ShareArticle> */}
          </div>
        </div>
      </div>
    </Layout>
  );
}
